import Phaser, { Cameras } from "phaser";
import Sword from "../components/sword";
import ItemController from "../components/Controller/ItemController";
import LevelProgressionImage from "../components/sprites/SliceItems/levelProgressionImage";
import SplashArtManager from "../components/Controller/splashArtController";
import UserInterface from "./userInterface";
import sessionSaveFile from "@/utils/game/SessionSaveFile";
import { ScoreDataDefault, ScoreGameTypes } from "@/interfaces/ISessionFile";
import scoreController from "@/utils/game/ScoreController";
import ItemBase from "../components/sprites/SliceItems/_ItemBase";

export default class Main extends Phaser.Scene {
    playerSword!: Sword
    itemManager!: ItemController
    clock!: Phaser.Time.Clock
    backgroundFreeze!: Phaser.GameObjects.Image
    splashArtManager!: SplashArtManager
    gameoverSoundEffect!: Phaser.Sound.BaseSound;
    gameover!: boolean
    sceneUserInterface!: UserInterface;

    constructor() {
        super({ key: "game" });
    }

    preload() {
        this.game.events.emit('start');

        /** USER INTERFACE */
        this.scene.launch('userinterface-game');
    }

    create() {
        sessionSaveFile.create({
            type: ScoreGameTypes.INFINITE,
            stats: ["biggestCombo", "topppingHit", "powerupHit", "playTime"]
        })

        sessionSaveFile.updateValue("playTime", new Date().getTime())
        this.sceneUserInterface = this.scene.get('userinterface-game') as UserInterface
        this.gameover = false

        /** GAME OBJECTS */
        // const background = this.add.image(this.cameras.main.centerX, this.cameras.main.centerY, 'background_wood');
        const levelProgression = new LevelProgressionImage(this, this.cameras.main.centerX, this.cameras.main.height);

        /** Managers */
        this.splashArtManager = new SplashArtManager(this)
        this.itemManager = new ItemController(this, this.splashArtManager, this.sceneUserInterface)
        this.playerSword = new Sword(this, this.cameras.main.centerX, this.cameras.main.centerY, this.itemManager);

        this.clock = new Phaser.Time.Clock(this)
        this.gameoverSoundEffect = this.sound.add('gameover_sound')

        /** EVENTS */
        this.events.on('onSlice', (item: ItemBase) => {
            this.sceneUserInterface.score.onSliceUpdate(item);
            this.events.emit('update levelProgression', 1, this.sceneUserInterface.score.currentScore)

            // TODO add freeze here
            switch (item.name) {
                case 'explode':
                    this.itemManager.sliceAllObjects()
                    break;
                case 'double':
                    this.events.emit('addDouble', true)
                    break;                

            }
        })

        this.events.on('gameOver', () => {
            this.gameover = true
            // this.splashArtManager.animateToMiddle(this.splashArtManager.gameoverText, 1000)
            this.gameoverSoundEffect.play()
            this.sceneUserInterface.health.loseAllHealth();
            this.shutdownEvents()

            sessionSaveFile.updateValue("biggestCombo", this.sceneUserInterface.score.highestCombo > this.sceneUserInterface.score.scoreCombo ? this.sceneUserInterface.score.highestCombo : this.sceneUserInterface.score.scoreCombo);
            sessionSaveFile.updateValue("topppingHit", this.itemManager.amountToppingHit);
            sessionSaveFile.updateValue("powerupHit", this.itemManager.amountpowerUpHit);
            const playTime = parseInt(`${sessionSaveFile.getValue("playTime")}`)
            sessionSaveFile.updateValue("playTime", (new Date().getTime() - playTime) / 1000 );
            sessionSaveFile.updateValue(ScoreDataDefault.TOTALSCORE, this.sceneUserInterface.score.currentScore)

            this.time.addEvent({
                delay: 2000,
                callbackScope: this,
                callback: this.shutdown
            })
        })
    }

    shutdownEvents() {
        this.events.off('onSlice')
        this.events.off('gameOver')
        this.events.off('toppingOutOfBounds')
        this.events.off('addDouble')
        this.events.off('showsplash')
        this.events.off('update levelProgression')
    }

    update(time: number, delta: number): void {
        this.clock.update(time, delta)
    }

    shutdown() {
        /** SCENES: cleanup */
        this.sceneUserInterface.scene.stop();

        const result = scoreController.getResult();
        this.game.events.emit('end', result);

        this.scene.stop()
    }
}
