import ItemBase, { SliceType } from "../_ItemBase"
import UiHealth from "../../../containers/uiHealth"
import UserInterface from "@/game/slicer/states/userInterface"

/**
 * EnemyBombItem
 * SliceItem when sliced make you lose the game
 */
export default class enemyBombItem extends ItemBase {
    uiHealth: UiHealth
    explosion: Phaser.Sound.BaseSound
    
    constructor(scene: Phaser.Scene, x: number, y: number) {
        super(scene, x, y, 'bomb', '', ['bomb_hit', 'bomb_hit2'], SliceType.enemy)
        this.setName('bomb')

        const UIScene = scene.scene.get('userinterface-game') as UserInterface
        this.uiHealth = UIScene.health
        this.explosion = this.scene.sound.add('explode_soundeffect')


        // make animaton if it doesnt exist
        if (!this.scene.anims.exists('explosion')) {
            this.scene.anims.create({
                key: 'explosion',
                frames: this.scene.anims.generateFrameNumbers('bomb_explosion', {start: 0, end: 13}),
                frameRate: 14,
                
            })
        }
    }

    revive(x: number, y: number) {
        super.revive(x,y)
        this.setTexture('bomb')

    }
    
    onSlice(): void {
        this.play('explosion', true)
        this.explosion.play()
        this.scene.events.emit('gameOver', this) // Receiver: main and SplashArtController
    }


}
