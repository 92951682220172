import Setup from "@/helpers/Setup";
import Constants from "../../configs/constants";
import Scaling from "../../configs/scaling";

/**
 * UIScore
 * handles the health of the player and displaying it
 */
export default class UiHealth extends Phaser.GameObjects.Container {
    livesImages: Phaser.GameObjects.Image[] = [];
    currentLives = 3;

    constructor(scene: Phaser.Scene, x: number, y: number) {
        super(scene, x, y)
        this.setName("uiHealth")
        this.scene.add.existing(this)
        
        const spacing = Scaling.getPixelbyDPR(30)
        for (let i = 0; i < Constants.LIVES_TOTAL; i++) {
            const life = this.scene.add.image(-(i * spacing), 0, 'icon_health_fill').setOrigin(0.5, 0.5);
            this.livesImages.push(life);
        }
        
        /** Create background */
        const lastLiveImage = this.livesImages[this.livesImages.length - 1];
        const background = this.scene.add.graphics();
        background.fillStyle(Phaser.Display.Color.HexStringToColor(Setup.getColor('primary')).color)
        background.fillRoundedRect(lastLiveImage.x - Scaling.getPixelbyDPR(20), lastLiveImage.y - Scaling.getPixelbyDPR(20), spacing * Constants.LIVES_TOTAL + Scaling.getPixelbyDPR(10), Scaling.getPixelbyDPR(40), 14)
        background.lineStyle(Scaling.getPixelbyDPR(2), 0xFFFFFF, 0.5)
        background.strokeRoundedRect(lastLiveImage.x - Scaling.getPixelbyDPR(20), lastLiveImage.y - Scaling.getPixelbyDPR(20), spacing * Constants.LIVES_TOTAL + Scaling.getPixelbyDPR(10), Scaling.getPixelbyDPR(40), 14)
        
        this.add([background, ...this.livesImages])
    }

    /**
     * Remove 1 health points from the player
     * @returns
     */
    loseHealth() {

        if (this.currentLives - 1 < 0) {
            return
        }

        this.currentLives -= 1;
        this.livesImages[this.currentLives].setTexture('icon_health_empty')
    }

    /**
     * remove all player health
     * is used by the bomb
     */
    loseAllHealth() {
        for (let i = 0; i < Constants.LIVES_TOTAL; i++) {
            this.loseHealth()
        }
    }
}
