import Constants from "@/game/slicer/configs/constants";
import ItemBase, { SliceType } from "../_ItemBase";

/**
 * PowerUpDoubleItem
 * SliceItem when sliced grants you double points for DOUBLE_TOTAL_DURATION
 */
export default class PowerupDoubleItem extends ItemBase {

    constructor(scene: Phaser.Scene, x: number, y: number) {
        super(scene, x, y, 'slice_items_powerups', 'powerup-double', ['slice_hit', `slice_hit2`], SliceType.powerup)
        this.scoreWorth = Constants.SCORE_BONUS
        this.setName("double")

        this.createChunks('slice_items_powerups', 'powerup-double')
    }

    onSlice() {
        super.onSlice()
        this.scene.events.emit('showsplash', 'double_text')
    }
}
