
/**
 * LevelProgressionImage
 * This is gameobject for easily change the graphic of the LevelProgressionImage
 */
export default class LevelProgressionImage extends Phaser.GameObjects.Image {
    imageKeys: string[]
    scoreTreshhold: number[]
    currentIndex: number

    constructor(scene: Phaser.Scene, x: number, y: number) {
        // this is the shadow of the backgroundiamge
        super(scene, x, y, 'level_progression_image1');
     
        this.setName('LevelProgressionImage')
        this.scene.add.existing(this)
        this.setOrigin(0.5, 1)
        this.setDisplaySize(scene.cameras.main.width, this.displayHeight)
        this.scoreTreshhold = [100, 250, 500, 666, 1000] // assumed that there is only 5 stages of mcflurries

        this.currentIndex = 0
        this.imageKeys = []
        for (let i = 1; i < 5 + 1; i++) {
            this.imageKeys.push(`level_progression_image${i}`)
        }        

        this.scene.events.on('update levelProgression', (index: number, score: number) => {
            this.addTextureIndex(index, score)
        })
    }

    private setTextureIndex(index: number) {
        if (index <= 0 && index >= this.imageKeys.length) {
            console.error(`${index} is larger than the amount of LevelProgressionImage`)
            return
        }

        this.currentIndex = index;
        this.setTexture(this.imageKeys[this.currentIndex])
    }

    addTextureIndex(index: number, score: number) {
        // check if in range of array
        if (this.currentIndex + index <= 0 || this.currentIndex + index >= this.imageKeys.length) {
            return
        }

        // check for score
        if (score < this.scoreTreshhold[this.currentIndex + index]) {
            return
        }

        this.currentIndex += index;
        this.setTextureIndex(this.currentIndex)
    }

    getIndex(): number {
        return this.currentIndex
    }
}
