

/**
 * SplashArtManager
 * animate images to the middle of the screen
 * and animate it back out of the screen
 */
export default class SplashArtManager {
    scene: Phaser.Scene

    bonusTimer!: Phaser.Time.TimerEvent
    middleImage: Phaser.GameObjects.Image[]
    lastImage!: Phaser.GameObjects.Image | undefined
    tweenIntro!: Phaser.Tweens.Tween
    timeholdTimer!: Phaser.Time.TimerEvent

    AllowToChange: boolean

    constructor(scene: Phaser.Scene) {
        this.scene = scene

        this.AllowToChange = true
        this.middleImage = []
        this.middleImage.push(scene.add.image(0, 0, 'gameover_text').setVisible(false))
        this.middleImage.push(scene.add.image(0, 0, 'double_text').setVisible(false))
        this.middleImage.push(scene.add.image(0, 0, 'explosion_text').setVisible(false))
        this.middleImage.push(scene.add.image(0, 0, 'freeze_text').setVisible(false))

        //scene.cameras.main.centerX, scene.cameras.main.centerY

        this.scene.events.on('gameOver', () => {
            this.animateToMiddle(this.middleImage.find(image => image.texture.key === 'gameover_text') as Phaser.GameObjects.Image, 2000)
            this.AllowToChange = false
        })

        this.scene.events.on('showsplash', (textureKey: string, callback?: () => void) => {
            
            this.animateToMiddle(this.middleImage.find(image => image.texture.key === textureKey) as Phaser.GameObjects.Image, 2000)
        })
    }

    /**
     * animate a single image to the middle of the screen
     * if there is already a image then move the item out of the screen
     * @param target image to animate towarsd the middle
     */
    animateToMiddle(target: Phaser.GameObjects.Image, duration: number) {
        if (!this.AllowToChange) {
            return
        }

        target.setVisible(true)
        target.setScale(0.2)
        target.setPosition(this.scene.cameras.main.centerX, this.scene.cameras.main.centerY)
        if (target !== this.lastImage) {

            this.tweenIntro = this.scene.add.tween({
                targets: target,
                duration: 750,
                ease: Phaser.Math.Easing.Quadratic.InOut,
                scale: { start: 0.2, to: 1 },
            })

            this.lastImage = target
            this.timeholdTimer = this.scene.time.addEvent({
                delay: duration,
                callback: () => {
                    target.setVisible(false)
                }
            })
        } else {
            if(!this.tweenIntro.isPlaying()) {
                this.tweenIntro.restart()
            }
            this.timeholdTimer.reset({
                delay: duration,
                callback: () => {
                    target.setVisible(false)
                }
            })
        }
    }

    /**
     * wrapper function for AnimateToMiddle
     * @param target image to animate towarsd the middle
     * @param duration amount time before calling the callback
     * @param callback function to call at the end of the duration
     */
    animateToMiddleCallback(target: Phaser.GameObjects.Image, duration: number, callback: CallableFunction) {
        this.animateToMiddle(target, duration)

        if (this.bonusTimer === undefined) {
            this.bonusTimer = this.scene.time.addEvent({
                delay: duration,
                callback: callback
            })
        } else {
            this.bonusTimer.reset({
                delay: duration,
                callback: callback
            })
        }
    }
}
