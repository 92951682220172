import UIScore from "../components/containers/uiScore"
import UIHealth from "../components/containers/uiHealth"
import Scaling from "../configs/scaling";

export default class UserInterface extends Phaser.Scene {
    score!: UIScore;
    health!: UIHealth

    constructor() {
        super({key: 'userinterface-game'})

    }

    create() {
        this.score = new UIScore(this, 0, Scaling.getPixelbyDPR(30));
        this.health = new UIHealth(this, this.cameras.main.width - Scaling.getPixelbyDPR(30), Scaling.getPixelbyDPR(90))
    }
}
