import { randomIntBetween } from "../../configs/helpers"
import UiHealth from "../containers/uiHealth"
import UIScore from "../containers/uiScore"
import Constants from "../../configs/constants"
import SliceItemsPool from "../groups/sliceItemsPool"
import ItemBase, { SliceType } from "../sprites/SliceItems/_ItemBase"
import SplashArtManager from "./splashArtController"
import ToppingItem from "../sprites/SliceItems/toppingItem"
import roundJson from "@/game/slicer/data/round_data.json"
import IRoundDataList, {IRoundData}   from "@/game/slicer/interfaces/IRoundData"
import UserInterface from "../../states/userInterface"
import Scaling from "../../configs/scaling"
import IDefaultRoundData from "../../interfaces/IDefaultRoundData"

/**
 * SliceItemsManager
 * handles all the ToppingObjects holding, throw in the air
 * and event for all the powerups items
 */
export default class ItemController {
    userinterfaceScene: UserInterface

    spawnL: Phaser.Math.Vector2
    spawnR: Phaser.Math.Vector2
    toppingPool: SliceItemsPool

    amountToppingHit: number
    amountpowerUpHit: number

    scene: Phaser.Scene
    splashManager: SplashArtManager

    roundData!: IRoundData[]
    roundCount: number

    constructor(scene: Phaser.Scene, splashManager: SplashArtManager, uiScene: UserInterface) {
        this.scene = scene
        this.userinterfaceScene = uiScene

        this.spawnL = new Phaser.Math.Vector2(0, this.scene.cameras.main.height)
        this.spawnR = new Phaser.Math.Vector2(this.scene.cameras.main.width, this.scene.cameras.main.height)

        this.amountToppingHit = 0
        this.amountpowerUpHit = 0
        this.splashManager = splashManager

        this.roundCount = 0
        const temp = roundJson as IRoundDataList
        this.roundData = temp.roundData

        this.toppingPool = new SliceItemsPool(scene, 10)

        const timeSpawn = this.scene.time.addEvent({
            loop: true,
            delay: Constants.ROUND_COOLDOWN,
            callback: () => {
                this.spawnRoundLoop(timeSpawn)
                
                timeSpawn.paused = true
                this.roundCount++;
            },
        })

        /**
         * This is called when a bomb is sliced and the game is lost
         */
        this.scene.events.on('gameOver', (item: ItemBase) => {
            timeSpawn.remove()
        })

        // sender: sliceItemBase
        this.scene.events.on('onSlice', (topping: ItemBase) => {
            this.handleSliceObject(topping)
        })

        // whenever a topping fell off screen
        this.scene.events.on('toppingOutOfBounds', (topping: ItemBase) => {
            if (!topping.isSliced) {
                this.userinterfaceScene.health.loseHealth()

                if(this.userinterfaceScene.health.currentLives <= 0) {
                    this.scene.events.emit('gameOver')
                } 
            }
        })

    }

    calcDifficulty(round: number) {
        const maxAmountItems = 8            // total items that can spawn
        const maxBombPerecentage = 10       // max bombs percentage (start from 0)
        const maxPowerupPercentage = 15     // max powerup percentage (start from 0)
        const maxRoundTimePerItem = 1250    // time between throwing  items (start from max)

        // set percentage for curve
        const start = 1 / 100;
        const control1 = 40 / 100
        const control2 = 70  / 100;
        const end = 100 / 100;
        
        const tValue = round <= maxAmountItems ? 1 / maxAmountItems * round : 1 // round higher than the max keep the max
        const difficulty = Phaser.Math.Interpolation.CubicBezier(tValue, start, control1, control2, end);

        const data: IDefaultRoundData = {
            amountItems: Math.floor(maxAmountItems * difficulty) <= 0 ? 1 : Math.floor(maxAmountItems * difficulty),
            bombPercentage: Math.floor(maxBombPerecentage * difficulty),
            powerupPercentage: Math.floor(maxPowerupPercentage * difficulty),
            roundTime: Math.floor(maxRoundTimePerItem * (1 - difficulty < 0.25 ? 0.25 : 1 - difficulty)) // do not allow it to go lower than 25%
        }
        return data;
    }

    /**
     * Loop for spawning SliceItem in rounds
     * @param amount how many per round
     * @param timer resume the loop
     */
    spawnRoundLoop(timer: Phaser.Time.TimerEvent) {
        let currentData: number[]
        let chosen: IRoundData
        const spawnData = this.calcDifficulty(this.roundCount);

        // check if there is data for this round to spawn
        for (let i = 0; i < this.roundData.length; i++) {
            const itemData = this.roundData[i];
            if(itemData.roundCount === this.roundCount) {
                chosen = itemData
                currentData = itemData.SliceItems
                spawnData.amountItems = itemData.SliceItems.length 
            }
        }

        let index = 0
        const roundTimer = this.scene.time.addEvent({
            delay: spawnData.roundTime,
            repeat: spawnData.amountItems - 1,
            callback: () => {
                if(currentData) {
                    this.spawnDataRound(chosen, index)
                } else {
                    
                    this.spawnDefaultRound(spawnData)
                }
                
                index++
                if (roundTimer.repeatCount <= 0)
                    timer.paused = false
            },

        })

        
    }

    private spawnDefaultRound(spawnData: IDefaultRoundData) {
        let spawnType: SliceType
        
        // randomize item type
        const percentage = randomIntBetween(0, 100);
        if (percentage < spawnData.bombPercentage) {
            spawnType = SliceType.enemy
        } else if (percentage < spawnData.powerupPercentage) {
            spawnType = SliceType.powerup
        } else {
            spawnType = SliceType.topping
        }

        this.spawnItem(spawnType)
    }

    private spawnDataRound(data: IRoundData, index: number) {
        this.spawnItem(data.SliceItems[index] as SliceType)
    }

    spawnItem(type: SliceType) {
        let spawnLeft = false
        let spawnPos
        let spawnForce
        const minForceX = 100
        const maxForceX = 250
        const minYForce = [300, 500, 600]
        const maxYForce = [550, 900, 1050]

        // random force and random position
        spawnLeft = randomIntBetween(0, 1) as unknown as boolean
        spawnPos = spawnLeft ? this.spawnL : this.spawnR
        const x = spawnLeft ? randomIntBetween(minForceX, maxForceX) : -randomIntBetween(minForceX, maxForceX)
        spawnForce = new Phaser.Math.Vector2(x, -randomIntBetween(minYForce[Scaling.DPR - 1], maxYForce[Scaling.DPR - 1]))

        // spawn and throw to the screen
        let sliceItem: ItemBase
        sliceItem = this.toppingPool.spawn(spawnPos.x, spawnPos.y, type)
        sliceItem.throw(spawnForce.x, spawnForce.y)
    }

    /**
     * animate the amount of score points towards the UIScore
     * @param topping ToppingItem that has been sliced
     */
    public handleSliceObject(topping: ItemBase) {
        if (topping.itemType === SliceType.topping) {
            this.amountToppingHit++;
        }
        else if (topping.itemType === SliceType.powerup) {
            this.amountpowerUpHit++;
        }

        const value = this.userinterfaceScene.score.scoreDouble? topping.scoreWorth * 2 : topping.scoreWorth
        const scoreText = this.scene.add.text(topping.x, topping.y, "+ " + value, Constants.TEXT_DEFAULT).setOrigin(0.5, 0.5)
        // score animation 
        this.scene.add.tween({
            targets: scoreText,
            x: this.userinterfaceScene.score.scoreText.x,
            y: this.userinterfaceScene.score.scoreText.y,
            alpha: { from: 1, to: 0.2 },
            onComplete: () => {
                scoreText.setActive(false).setVisible(false)
            }
        })
    }

    /**
     * Slice all current SliceItems on screen
     */
    sliceAllObjects() {
        const currentScreenSliceItem = this.getAvailableSliceObjects()
        currentScreenSliceItem.forEach((sliceItem: ToppingItem) => {

            switch (sliceItem.itemType) {
                case SliceType.enemy:
                    sliceItem.kill()
                    sliceItem.activateSlicedChunks()
                    break;
                case SliceType.powerup:
                case SliceType.topping:
                    sliceItem.slice()
                    break
            }

        })
    }

    getAvailableSliceObjects(): ItemBase[] {
        return this.toppingPool.getMatching('isDone', false) as ItemBase[]
    }
    
}

