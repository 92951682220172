import Scaling from "@/game/slicer/configs/scaling";
import UserInterface from "@/game/slicer/states/userInterface";
import { randomIntBetween } from "../../../configs/helpers";

/**
 * SliceItemBase
 * base class for all sliceItem
 * offers basic function for sliceItems
 */
export default abstract class ItemBase extends Phaser.Physics.Arcade.Sprite {
    isSliced = false
    isDone = false
    chunks: Phaser.Physics.Arcade.Image[] = []
    throwSoundEffect: Phaser.Sound.BaseSound[] = []
    scoreWorth = 0
    itemType: SliceType
    hitSound: Phaser.Sound.BaseSound

    constructor(scene: Phaser.Scene, x: number, y: number, keyName: string, frameName: string, audioName: string[], type: SliceType) {
        super(scene, x, y, keyName, frameName)

        // sound
        this.hitSound = this.scene.sound.add(audioName[randomIntBetween(0, audioName.length - 1)], {
            volume: 0.5,
        })
        this.throwSoundEffect.push(this.scene.sound.add('throw_object', { volume: 0.25 }))
        this.throwSoundEffect.push(this.scene.sound.add('throw_object2', { volume: 0.25 }))

        // add object
        this.scene.physics.add.existing(this)
        this.scene.sys.displayList.add(this)
        this.scene.sys.updateList.add(this)

        this.itemType = type
    }

    /**
     * Create the halves of the SliceItem
     * @param keyName what is the key of the texture
     * @param frameName what frame does it belong on the texture
     */
    protected createChunks(keyName: string, frameName?: string) {
        if (frameName !== undefined && frameName !== null) {
            let index = 1
            while (this.scene.textures.get(keyName).has(`${frameName}-sliced-${index}`)) {
                this.chunks.push(this.scene.physics.add.image(0, 0, keyName, frameName + `-sliced-${index}`).setActive(false).setVisible(false))
                index++
            }
        }
    }

    /**
     * Create slices that spawn on the objectSlice
     * and add a random force
     * the amount of slices spawn is equal to the array size
     * @param textureNames array of texture of the chunks 
     */
    activateSlicedChunks() {
        let toggle = true
        for (let i = 0; i < this.chunks.length; i++) {
            const element = this.chunks[i];
            element.setVisible(true)
            element.setActive(true)
            element.setPosition(this.x, this.y)

            const randomForceX = toggle ? -1 : 1
            toggle = false
            element.setVelocity(this.body.velocity.x / randomIntBetween(1, 20) * randomForceX, this.body.velocity.y)
        }

    }

    /**
     * Set the SliceItem to alive for objectpoolling
     * @param x new x position
     * @param y new y position
     */
    revive(x = 0, y = 0) {
        this.isDone = false;
        this.isSliced = false
        this.setPosition(x, y)
        this.setVisible(true)
        this.setActive(true)
    }

    /**
     * Set the SliceItem to dead for objectpoolling
     */
    kill() {
        this.setVisible(false)
        this.setActive(false)
    }

    slice() {
        if (this.isSliced) {
            return
        }

        this.isSliced = true

        this.onSlice()
        this.hitSound.play()
        this.activateSlicedChunks()
    }

    /** Event when this object has been hit by the cursor */
    onSlice(): void {
        this.scene.events.emit('onSlice', this)
        this.kill()
    }

    /** Event when this object is out of the of the camera */
    onOutOfBounds(): void {

    }

    throw(x: number, y: number) {
        this.setVelocity(x, y)

        this.throwSoundEffect[randomIntBetween(0, this.throwSoundEffect.length - 1)].play()
    }

    protected preUpdate(time: number, delta: number): void {
        this.anims.update(time, delta)

        if (this.isDone)
            return;

        // check if item is outofbounds
        if (!Phaser.Geom.Rectangle.Overlaps(this.scene.physics.world.bounds, this.getBounds())) {
            this.isDone = true
            this.onOutOfBounds()
        }
    }

}

export enum SliceType {
    topping,
    powerup,
    enemy
}
