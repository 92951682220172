import Setup from "@/helpers/Setup"
import Constants from "../../configs/constants"
import Scaling from "../../configs/scaling"
import Main from "../../states/main"
import Progressbar from "./progressbar"
import ItemBase from "../sprites/SliceItems/_ItemBase"

/**
 * UIScore
 * handles the score of the player and displaying it
 */
export default class UIScore extends Phaser.GameObjects.Container {
    scoreText: Phaser.GameObjects.Text
    comboText: Phaser.GameObjects.Text
    currentScore: number

    soundDetune: number
    bonusImage: Phaser.GameObjects.Image

    comboSoundEffect: Phaser.Sound.BaseSound
    comboRewardSoundEffect: Phaser.Sound.BaseSound
    comboBar: Progressbar
    comboTween: Phaser.Tweens.Tween

    scoreCombo: number
    highestCombo: number
    comboCooldown: number // in ms
    multiplierTimer!: Phaser.Tweens.Timeline // in ms
    scoreDouble: boolean

    constructor(scene: Phaser.Scene, x: number, y: number) {
        super(scene, x, y)
        this.setName('uiScore');
        this.scene.add.existing(this);

        /** SETUP */
        this.scoreCombo = 0
        this.currentScore = 0
        this.highestCombo = 0
        this.comboCooldown = 3000
        this.soundDetune = 0
        this.scoreDouble = false

        this.bonusImage = this.scene.add.image(this.scene.cameras.main.width * 2, this.scene.cameras.main.centerY, 'bonus')

        /** CREATING UI */
        /** Background */
        const background = this.scene.add.graphics();
        background.fillStyle(0xFFFFFF)
        background.fillRoundedRect(Scaling.getPixelbyDPR(20), Scaling.getPixelbyDPR(40), Scaling.getPixelbyDPR(120), Scaling.getPixelbyDPR(40), 14)
        background.lineStyle(Scaling.getPixelbyDPR(2), 0xFFFFFF, 0.5)
        background.strokeRoundedRect(Scaling.getPixelbyDPR(20), Scaling.getPixelbyDPR(40), Scaling.getPixelbyDPR(120), Scaling.getPixelbyDPR(40), 14)

        /** Icon background */
        const iconBackground = this.scene.add.graphics()
        iconBackground.fillStyle(Phaser.Display.Color.HexStringToColor(Setup.getColor('primary')).color)
        iconBackground.fillRoundedRect(Scaling.getPixelbyDPR(20), Scaling.getPixelbyDPR(40), Scaling.getPixelbyDPR(40), Scaling.getPixelbyDPR(40), 14)

        /** Icon */
        const icon = this.scene.add.image(Scaling.getPixelbyDPR(40), Scaling.getPixelbyDPR(60), 'icon_score').setName('scoreIcon')

        this.scoreText = this.scene.add.text(icon.getCenter().x + Scaling.getPixelbyDPR(60), icon.getCenter().y, `${this.currentScore}`, {
            color: "0xFFFFFF",
            fontFamily: Constants.FONT_BOLD,
            fontSize: `${Scaling.getPixelbyDPR(16)}px`,
        }).setName('uiScoreChild').setOrigin(0.5, 0.5);

        this.add([background, iconBackground, icon, this.scoreText])

        /** SOUND EFFECT */
        this.comboSoundEffect = this.scene.sound.add('combo_soundeffect', { volume: 0.45, detune: 0 })
        this.comboRewardSoundEffect = this.scene.sound.add('score_soundeffect', { volume: 0.5 })

        // COMBO PROGRESSBAR
        const oncompleteCallback = this.onCompleteBar.bind(this)
        this.comboBar = new Progressbar(scene, background.x + Scaling.getPixelbyDPR(20), icon.getBottomLeft().y + Scaling.getPixelbyDPR(35), 3000, oncompleteCallback)

        this.comboText = this.scene.add.text(this.comboBar.x + Scaling.getPixelbyDPR(10), this.comboBar.y, `${Setup.getCopy('general.combo')}: ${this.scoreCombo}X`, {
            fontSize: `${16 * Scaling.DPR}px`,
            fontFamily: Constants.FONT_REGULAR,
        }).setOrigin(0, 0.5)
        this.comboTween = this.scene.tweens.add({ targets: this.comboText })

        this.add([this.comboBar, this.comboText])


        /** EVENTS */
        const gameScene = this.scene.scene.get('game') as Main

        gameScene.events.on('addDouble', () => {
            this.scoreDouble = true

            scene.time.addEvent({
                delay: Constants.DOUBLE_TOTAL_DURATION,
                callback: () => {
                    this.scoreDouble = false
                }
            })
        })
    }


    createUserinterface() {
        /** Background */
        const background = this.scene.add.graphics();
        background.fillStyle(0xFFFFFF)
        background.fillRoundedRect(Scaling.getPixelbyDPR(20), Scaling.getPixelbyDPR(40), Scaling.getPixelbyDPR(120), Scaling.getPixelbyDPR(40), 14)
        background.lineStyle(Scaling.getPixelbyDPR(2), 0xFFFFFF, 0.5)
        background.strokeRoundedRect(Scaling.getPixelbyDPR(20), Scaling.getPixelbyDPR(40), Scaling.getPixelbyDPR(120), Scaling.getPixelbyDPR(40), 14)

        /** Icon background */
        const iconBackground = this.scene.add.graphics()
        iconBackground.fillStyle(Phaser.Display.Color.HexStringToColor(Setup.getColor('primary')).color)
        iconBackground.fillRoundedRect(Scaling.getPixelbyDPR(20), Scaling.getPixelbyDPR(40), Scaling.getPixelbyDPR(40), Scaling.getPixelbyDPR(40), 14)

        /** Icon */
        const icon = this.scene.add.image(Scaling.getPixelbyDPR(40), Scaling.getPixelbyDPR(60), 'icon_score').setName('scoreIcon')

        this.scoreText = this.scene.add.text(icon.getCenter().x + Scaling.getPixelbyDPR(60), icon.getCenter().y, `${this.currentScore}`, {
            color: "0xFFFFFF",
            fontFamily: Constants.FONT_BOLD,
            fontSize: `${Scaling.getPixelbyDPR(14)}px`
        }).setName('uiScoreChild').setOrigin(0.5, 0.5);

        this.add([background, iconBackground, icon, this.scoreText])
        return [background, iconBackground, icon, this.scoreText]
    }

    onSliceUpdate(sliceItem: ItemBase) {
        if (this.scoreDouble) {
            this.currentScore += sliceItem.scoreWorth * 2
        } else {
            this.currentScore += sliceItem.scoreWorth
        }
        this.scoreCombo++;
        this.soundDetune++;

        this.scoreText.setText(`${this.currentScore}`)
        this.comboText.setText(`STREAk: ${this.scoreCombo}X`)

        // Sound
        if (this.soundDetune < 10) {
            this.comboSoundEffect.play({ detune: 100 * this.soundDetune })
        } else if (this.scoreCombo % 10 == 0) {
            this.comboRewardSoundEffect.play()
            this.currentScore += 100
            this.soundDetune = 0;
        }

        // Score animation
        this.comboTween.stop()

        this.comboTween = this.scene.tweens.add({
            targets: this.comboText,
            duration: 250,
            yoyo: true,
            scale: {from: 1, to: 1.05}
        })

        this.comboBar.restartBar()
    }

    /**
     * Called from progressbar when the bar completes
     */
    onCompleteBar() {
        if (this.highestCombo < this.scoreCombo) {
            this.highestCombo = this.scoreCombo
        }

        this.scoreCombo = 0
        this.soundDetune = 0
        this.comboText.setText(`${this.scoreCombo}X`)
    }

}
