import Constants from "../../configs/constants"
import Scaling from "../../configs/scaling";

// TODO future if have enough time split the depleting bar in two
// first part goes pretty fast
// the second part goed real slow
export default class Progressbar extends Phaser.GameObjects.Container {
    multiplierTimer!: Phaser.Tweens.Timeline // in ms
    multiplierTimerconfig: Phaser.Types.Tweens.TimelineBuilderConfig
    progressTotalDuration: number // in ms

    barEmpty: Phaser.GameObjects.Image
    barFilled: Phaser.GameObjects.Image

    constructor(scene: Phaser.Scene, x: number, y: number, duration: number, onCompleteBar: () => any) {
        super(scene, x, y)

        /** GENERAL */
        this.scene = scene

        /** SETTINGS */
        this.progressTotalDuration = duration

        /** PROGRESSBAR SETTINGS */
        this.barEmpty = scene.add.image(0, 0, 'streak_bar_empty').setOrigin(0, 0.5)
        this.barFilled = scene.add.image(0, 0, 'streak_bar_filled').setOrigin(0, 0.5)

        const graphic = scene.make.graphics({})
        graphic.fillRoundedRect(x, y + Scaling.getPixelbyDPR(18), this.barEmpty.width, this.barEmpty.height, Scaling.getPixelbyDPR(10))
        const mask = graphic.createGeometryMask()
        this.barFilled.setMask(mask)

        this.add([this.barEmpty, this.barFilled])

        /** ANIMATION SETTINGS */
        this.multiplierTimerconfig = {
            targets: this.barFilled,
            tweens: [{
                duration: this.progressTotalDuration,
                scaleX: { from: 1, to: 0.2 },
                onUpdate: () => {
                }

            },
            {
                duration: this.progressTotalDuration / 2,
                scaleX: { from: 0.2, to: 0 },
            }],
            onComplete: onCompleteBar,
        }


        this.multiplierTimer = scene.tweens.timeline(this.multiplierTimerconfig)
    }

    /**
     * Start the bar back to the beginning
     */
    restartBar() {
        this.multiplierTimer.stop()
        this.multiplierTimer = this.scene.tweens.timeline(this.multiplierTimerconfig)
    }
}