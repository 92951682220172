import Setup from "@/helpers/Setup";
import Constants from "../configs/constants";
import Scaling from "../configs/scaling";
import Background from "./background";
import CircularProgress from 'phaser3-rex-plugins/plugins/circularprogress';

export default class Load extends Phaser.Scene {
    constructor() {
        super({
            key: "load"
        });
    }

    preload() {
        /** LOGO */
        const logoTitle = this.add.image(this.cameras.main.centerX, Scaling.getPixelbyDPR(32), 'logo_mcflurry_title').setOrigin(0.5, 0);
        const logo = this.add.image(this.cameras.main.centerX, logoTitle.getBounds().bottom + Scaling.getPixelbyDPR(12), 'logo_mcflurry').setOrigin(0.5, 0);

        /** LOADER */
        const circleloaded = new CircularProgress(this, {
            x: this.cameras.main.centerX,
            y: this.cameras.main.height - Scaling.getPixelbyDPR(80),
            radius: Scaling.getPixelbyDPR(30),
            thickness: 0.1,
            trackColor: 0x000000,
            barColor: 0xFFFFFF,
            valuechangeCallback: () => { }
          });
          this.add.existing(circleloaded);

        /** LOAD IMAGES */
        // titlescreen
        const language = Setup.getLanguage();
        this.load.image("startgame_button", Scaling.ImagePath(`button_start_${language.toLowerCase()}`, 'png'))

        // game
        this.load.image("background_wood_freeze", Scaling.ImagePath(`background_freeze`, 'png'))
        this.load.image('explosion_text', Scaling.ImagePath('text_explosion', 'png'))
        this.load.image('gameover_text', Scaling.ImagePath(`text_gameover`, 'png'))
        this.load.image('double_text', Scaling.ImagePath(`text_bonus`, 'png'))
        this.load.image('freeze_text', Scaling.ImagePath('text_freeze', 'png'))        

        // UI
        this.load.image("icon_score", Scaling.ImagePath(`icon-score`, `png`))
        this.load.image('icon_health_fill', Scaling.ImagePath(`icon_life_fill`, `png`))
        this.load.image('icon_health_empty', Scaling.ImagePath(`icon_life_empty`, `png`))
        this.load.image('bonus', Scaling.ImagePath(`text_bonus`, `png`))
        this.load.image('streak_bar_filled', Scaling.ImagePath('streakbar-full', 'png'))
        this.load.image('streak_bar_empty', Scaling.ImagePath('streakbar-empty', 'png'))

        // load the game background item
        for (let i = 1; i < 5 + 1; i++) {
            this.load.image(`level_progression_image${i}`, Scaling.ImagePath(`game_background_item_${i}`, `png`))
        }


        // loat atlas
        this.load.atlas('slice_items', Scaling.ImagePath(`slice-items`, `png`), Scaling.ImagePath(`slice-items`, 'json'))
        this.load.atlas('sliced_items', Scaling.ImagePath(`sliced-items`, `png`), Scaling.ImagePath(`sliced-items`, 'json'))
        this.load.atlas('slice_items_powerups', Scaling.ImagePath(`powerup-items`, `png`), Scaling.ImagePath(`powerup-items`, 'json'))
        this.load.json('slice_json', Scaling.ImagePath(`slice-items`, 'json'))

        // load bomb assets
        this.load.image('bomb', Scaling.ImagePath(`bomb`, `png`))
        this.load.spritesheet('bomb_explosion', Scaling.ImagePath(`bomb_sprite`, `png`), { frameWidth: Scaling.getPixelbyDPR(80), frameHeight: Scaling.getPixelbyDPR(80), endFrame: 13 })

        /** AUDIO */

        this.load.audio("button_soundeffect", [`${Constants.SOUNDPATH}sfx_button.mp3`, `${Constants.SOUNDPATH}sfx_button.ogg`]);
        this.load.audio("theme", [`${Constants.SOUNDPATH}background_1.mp3`, `${Constants.SOUNDPATH}background_1.ogg`])
        this.load.audio('slice_hit', [`${Constants.SOUNDPATH}sfx_hitobjects.mp3`, `${Constants.SOUNDPATH}sfx_hitobjects.ogg`])
        this.load.audio('slice_hit2', [`${Constants.SOUNDPATH}sliced.mp3`, `${Constants.SOUNDPATH}sliced.ogg`])
        this.load.audio('bomb_hit', [`${Constants.SOUNDPATH}sfx_hitbomb.mp3`, `${Constants.SOUNDPATH}bomb_hit.ogg`])
        this.load.audio('bomb_hit2', [`${Constants.SOUNDPATH}sfx_hitbomb2.mp3`, `${Constants.SOUNDPATH}bomb_hit2.ogg`])
        this.load.audio('explode_soundeffect', [`${Constants.SOUNDPATH}sfx_bombexplosion.mp3`, `${Constants.SOUNDPATH}explode_soundeffect.ogg`])
        this.load.audio('throw_object', [`${Constants.SOUNDPATH}sfx_throwobjects.mp3`, `${Constants.SOUNDPATH}throw_object.ogg`])
        this.load.audio('throw_object2', [`${Constants.SOUNDPATH}sfx_throwobjects2.mp3`, `${Constants.SOUNDPATH}throw_object2.ogg`])
        this.load.audio('score_soundeffect', [`${Constants.SOUNDPATH}sfx_points.mp3`, `${Constants.SOUNDPATH}score_soundeffect.ogg`])
        this.load.audio('combo_soundeffect', [`${Constants.SOUNDPATH}combo_1.mp3`, `${Constants.SOUNDPATH}combo_soundeffect.ogg`])
        this.load.audio('swoosh_soundeffect', [`${Constants.SOUNDPATH}sfx_swing.mp3`, `${Constants.SOUNDPATH}swoosh_soundeffect.ogg`])
        this.load.audio('swoosh_soundeffect2', [`${Constants.SOUNDPATH}sfx_swing_2.mp3`, `${Constants.SOUNDPATH}sfx_swing_2.ogg`])
        this.load.audio('gameover_sound', [`${Constants.SOUNDPATH}gameover.mp3`, `${Constants.SOUNDPATH}gameover.ogg`])


        /** PRELOAD: progression */
        this.load.on("progress", (value: number) => {
            circleloaded.setValue(value)
        });

        /** PRELOAD: complete */
        this.load.on("complete", () => {

            this.time.addEvent({
                delay: 500,
                callback: () => {
                    circleloaded.setVisible(false);

                    const audio_button = this.sound.add('button_soundeffect', { volume: 0.1 });
                    audio_button.play();

                    const backgroundScene = this.scene.get("background") as Background;
                    if (backgroundScene) {
                        backgroundScene.playMusic();

                    }                        
                    this.game.events.emit("loaded"); 

                    const proceedText = this.add.text(this.cameras.main.centerX, this.cameras.main.height - Scaling.getPixelbyDPR(64), Setup.getCopy('general.touchStart'), {
                        fixedWidth: this.scene.systems.canvas.width,
                        align: "center",
                        fontFamily: Constants.FONT_BOLD,
                        fontSize: `${Scaling.getPixelbyDPR(18)}px`,
                        color: "0xFFFFFF"
                      }).setOrigin(0.5, 0);
                    logo.setVisible(false);

                    this.tweens.add({
                        targets: proceedText,
                        scale: { to: 1.1, from: 1 },
                        yoyo: true,
                        repeat: -1,
                        duration: 1000,
                        ease: "Power1"
                    });
                },

            })
        });
    }


}


