import ItemBase, { SliceType } from "./_ItemBase";

/**
 * ToppingObject
 * SliceItem when sliced grant the player SCORE_DEFAULT amount of points
 * when onOutOfBounds players get 1 healthpoint taken off
 */

export default class ToppingItem extends ItemBase {
    constructor(scene: Phaser.Scene, x: number, y: number, frameName: string) {
        super(scene, x, y, 'slice_items', frameName, ['slice_hit', `slice_hit2`], SliceType.topping)
        this.setName('topping')
        this.scoreWorth = 10

        this.createChunks('sliced_items', frameName)
    }
    
    onOutOfBounds(): void {
        // Receiver: ToppingManager
        this.scene.events.emit('toppingOutOfBounds', this)
        this.kill()
    }

}
