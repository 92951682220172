// @ts-ignore
import WebFont from 'webfontloader'
import Phaser from 'phaser'
import Constants from '../configs/constants'
import Scaling from '../configs/scaling'

export default class Boot extends Phaser.Scene {
    constructor() {
        super({
            key: 'boot',
        })
    }

    preload() {
        // Load Fonts
        this.load.on('complete', () => {
            WebFont.load({
                custom: {
                    families: [Constants.FONT_BOLD, Constants.FONT_REGULAR],
                },
                active: () => this.booted(true),
                inactive: () => this.booted(false),
            })
        })
        
        
        this.load.image("background_wood", Scaling.ImagePath(`background_game`, `jpg`)) // key: background_main
        this.load.image('logo_mcflurry', Scaling.ImagePath(`home_icon`, `png`)) // key: logo_company
        this.load.image('logo_mcflurry_title', Scaling.ImagePath(`logo`, `png`)) // key: logo_campaign
    }

    booted(fontsLoaded: boolean) {
        // Fonts: Loaded check
        if (!fontsLoaded) {
            return console.error('fonts failed to load...')
        }

        // Scene: launch parallel background scene
        this.scene.launch('background')
        this.scene.stop()
    }
}
