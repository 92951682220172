import { randomIntBetween } from "../../configs/helpers";
import ToppingItem from "../sprites/SliceItems/toppingItem";
import { ISliceData } from "../../interfaces/IToppingData"
import ItemBase, { SliceType } from "../sprites/SliceItems/_ItemBase";
import powerupFreezeItem from "../sprites/SliceItems/powerups/powerupFreezeItem";
import enemyBombItem from "../sprites/SliceItems/enemies/enemyBombItem";
import PowerupExplodeItem from "../sprites/SliceItems/powerups/powerupExplodeItem";
import PowerupDoubleItem from "../sprites/SliceItems/powerups/powerupDoubleItem";
import Scaling from "../../configs/scaling";

/**
 * SliceItemsPool
 * Helperclass that spawn or return a gameobject depening on the arguments
 */
export default class SliceItemsPool extends Phaser.GameObjects.Group {
    itemKeys: string[]


    constructor(scene: Phaser.Scene, startAmount: number) {
        super(scene)

        const toppingItemTextures = scene.cache.json.get('slice_json');
        this.itemKeys = Object.keys(toppingItemTextures.frames)

        for (let i = 0; i < startAmount; i++) {
            // normal topping
            this.createTopping().kill()

            // powerups
            this.createPowerup().kill()

            // enemy bombs
            this.createEnemy().kill()
        }
    }

    /**
     * return a SliceItem based on the type you want
     * it either get an existing SliceItem 
     * or spawns a new SliceItem 
     * @param x xposition of SliceItem
     * @param y yposition of SliceItem
     * @param type Type of the SliceItem you want
     * @returns return a SliceItem depending on the {type} argument
     */
    spawn(x: number, y: number, type: SliceType, startAlive = true) {
        const relevantItems = this.getMatching('itemType', type) as ItemBase[]
        let sliceObject

        // get object from this group
        for (let i = 0; i < relevantItems.length; i++) {
            const element = relevantItems[i];
            if (element.isDone) {
                sliceObject = element
                sliceObject.revive(x, y)
                return sliceObject
            }
        }

        // spawn new one in
        switch (type) {
            case SliceType.topping:
                sliceObject = this.createTopping()
                break
            case SliceType.powerup:
                sliceObject = this.createPowerup()
                break
            case SliceType.enemy:
                sliceObject = this.createEnemy()
                break
        }

        // return the item back to the manager
        if(startAlive){
            sliceObject?.revive(x, y)
        }
        return sliceObject as ItemBase
    }


    private createTopping() {
        const index = randomIntBetween(0, this.itemKeys.length - 1)
        const tempTopping = new ToppingItem(this.scene, 0, 0, this.itemKeys[index]).setActive(false).setVisible(false)
        tempTopping.isDone = true
        this.add(tempTopping, false)
        return tempTopping
    }

     private createPowerup() {
        const index = randomIntBetween(0, 2)

        let tempPowerup
        switch (index) {
            case 0:
                tempPowerup = new powerupFreezeItem(this.scene, 0, 0)
                tempPowerup.isDone = true
                this.add(tempPowerup)
                break
            case 1:
                tempPowerup = new PowerupExplodeItem(this.scene, 0, 0)
                tempPowerup.isDone = true
                this.add(tempPowerup)
                break;
            case 2:
                tempPowerup = new PowerupDoubleItem(this.scene, 0, 0)
                tempPowerup.isDone = true
                this.add(tempPowerup)
                break;
        }
        return tempPowerup as powerupFreezeItem | PowerupExplodeItem | PowerupDoubleItem
    }

    createEnemy() {
        const tempEnemy = new enemyBombItem(this.scene, 0, 0).setActive(false).setVisible(false)
        tempEnemy.isDone = true
        this.add(tempEnemy)
        return tempEnemy
    }

}

