import Scaling from "./scaling";

export default class Constants {
    /** PATHS */
    static IMAGEPATH = `/images/games/slicer/`
    static SOUNDPATH = `/sounds/games/slicer/`

    /** FONTS */
    static FONT_BOLD = "font_bold";
    static FONT_REGULAR = "font_regular";

    /** TEXT_STYLE */
    static TEXT_DEFAULT: Phaser.Types.GameObjects.Text.TextStyle = {
        fontSize: `${16 * Scaling.DPR}px`,
        fontFamily: Constants.FONT_REGULAR

    }

    /** COLORS */
    static COLOR_YELLOW_HEX = '#FFB300';
    static COLOR_YELLOW_INT = 0xFFB300;
    static COLOR_BLACK_HEX = '#000000';
    static COLOR_BLACK_INT: any = '#000000';
    static COLOR_WHITE_HEX = '#FFFFFF';
    static COLOR_WHITE_INT = 0xffffff;    
    static COLOR_GREEN_INT: any = "AAFF00";

    /** GAMEPLAY */
    static LANGAUGE = 'en'
    
    static LIVES_TOTAL = 3
    static ROUND_COOLDOWN = 2000             // cooldown in ms between rounds
    static FREEZE_TOTAL_DURATION = 4000      // Total time freeze time when hitting freeze powerup
    static DOUBLE_TOTAL_DURATION = 10000     // Total time where the player gains double points

    static SCORE_DEFAULT = 10                // default score earned
    static SCORE_BONUS = 100                 // combo score earned
    
}
