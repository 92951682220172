import Phaser from "phaser";
import Scaling from "../configs/scaling";

export default class Background extends Phaser.Scene {
  tileSpritePattern!: Phaser.GameObjects.TileSprite;
  music?: Phaser.Sound.BaseSound;

  constructor() {
    super({ key: "background" });
  }

  create() {
    this.cameras.main.setBackgroundColor(0xFFFFFF);
    // TODO ask for new assets
    this.add.image(this.cameras.main.centerX, this.cameras.main.centerY, 'background_wood').setDisplaySize(this.cameras.main.width, this.cameras.main.height)

    // Notify that game has booted
    this.game.events.emit("booted");
  }

  setColor(color: number) {
    this.cameras.main.setBackgroundColor(color ? color : 0xFFFFFF);
  }

  playMusic() {
    this.stopMusic();

    //Create music instance & play
    this.music = this.sound.add("theme");
    this.music.play({ loop: true, volume: 0.05 });
  }

  stopMusic() {
    if (this.music) {
      this.music.destroy();
    }
  }

  update(time: number, delta: number) {
  }
}