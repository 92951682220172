import Constants from "@/game/slicer/configs/constants";
import ItemBase from "../_ItemBase";
import { SliceType } from "../_ItemBase";

/**
 * PowerUpDoubleItem
 * SliceItem when sliced slows down phyics speed to half for FREEZE_TOTAL_DURATION
 */
export default class powerupFreezeItem extends ItemBase {
    tweenConfigIn: Phaser.Types.Tweens.TweenBuilderConfig | object
    tweenConfigHold: Phaser.Types.Tweens.TweenBuilderConfig | object
    tweenConfigOut: Phaser.Types.Tweens.TweenBuilderConfig | object
    freezebackground: Phaser.GameObjects.Image

    constructor(scene: Phaser.Scene, x: number, y: number) {
        super(scene, x, y, 'slice_items_powerups', 'powerup-freeze', ['slice_hit'], SliceType.powerup)
        this.itemType = SliceType.powerup
        this.setName('freeze')
        this.scoreWorth = Constants.SCORE_BONUS

        this.createChunks('slice_items_powerups', 'powerup-freeze')

        this.freezebackground = this.scene.children.getByName('freezeBG') as Phaser.GameObjects.Image
        if (this.freezebackground === null) {
            this.freezebackground = this.scene.add.image(0, 0, 'background_wood_freeze').setAlpha(0).setOrigin(0).setDepth(2).setDisplaySize(scene.cameras.main.width, scene.cameras.main.height);
        }

        this.tweenConfigIn = {
            targets: this.freezebackground,
            alpha: { from: 0, to: 0.8 },
            duration: 1000,
            onComplete: () => {
                this.scene.add.tween(this.tweenConfigHold)
                this.scene.physics.world.timeScale = 2
            }
        }

        this.tweenConfigHold = {
            targets: this.freezebackground,
            alpha: 1,
            duration: Constants.FREEZE_TOTAL_DURATION,
            onComplete: () => {
                this.scene.add.tween(this.tweenConfigOut)
                this.scene.physics.world.timeScale = 2

            }
        }
        this.tweenConfigOut = {
            targets: this.freezebackground,
            alpha: { from: 0.8, to: 0 },
            duration: 1000,
            onComplete: () => {
                this.scene.physics.world.timeScale = 1
            }
        }

    }

    onSlice(): void {
        super.onSlice()
        this.scene.events.emit('showsplash', 'freeze_text')
        // emit event for freeze 
        // receiver main
        this.freezeEvent()

    }

    /**
 * slowdown all the physics in the world
 * and adding a freeze overlay to the screen
 */
    freezeEvent() {
        this.scene.tweens.getAllTweens().forEach((tween) => {
            // find the right tween

            if (tween.targets !== undefined && tween.targets[0] === this.freezebackground) {
                if (tween.isPlaying()) {
                    tween.remove()                    

                    this.scene.physics.world.timeScale = 2
                    this.scene.tweens.add(this.tweenConfigHold)
                    return
                }
            }
        })
        this.scene.tweens.add(this.tweenConfigIn)
    }

}
