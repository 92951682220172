import Constants from "@/game/slicer/configs/constants"
import ItemBase, { SliceType } from "../_ItemBase"

/**
 * PowerUpDoubleItem
 * SliceItem when sliced clears the screen
 */
export default class PowerupExplodeItem extends ItemBase {
    constructor(scene: Phaser.Scene, x: number, y: number) {
        super(scene, x, y, 'slice_items_powerups', 'powerup-explode', ['slice_hit', `slice_hit2`], SliceType.powerup)
        this.setName('explode')
        this.createChunks('slice_items_powerups', 'powerup-explode')
        this.scoreWorth = Constants.SCORE_BONUS
    }

    onSlice(): void {
        super.onSlice()

        this.scene.events.emit('showsplash', 'explosion_text') // receiver SliceItemManager
    }


}