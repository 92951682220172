
/**
 * Sword class
 * This handles all the particles effect for the mouse cursor
 * and check if a SliceItem is sliced
 */
import Scaling from "@/game/match3/configs/scaling";
import Constants from "../configs/constants";
import { linearInterpolation, randomIntBetween } from "../configs/helpers";
import SwordPoint from "../interfaces/ISwordPoint";
import Main from "../states/main";
import ItemController from "./Controller/ItemController";
import ItemBase from "./sprites/SliceItems/_ItemBase";

export default class Sword extends Phaser.GameObjects.Graphics {
    lastPoints: SwordPoint[]
    startingPoint: Phaser.Math.Vector2
    endingPoint: Phaser.Math.Vector2
    sliceItemManager: ItemController
    
    soundReady = true // ready to play sound determined by the soundTimer
    slashFX: Phaser.Sound.BaseSound[] = []
    
    declare scene: Main
    constructor(scene: Phaser.Scene, x: number, y: number, itemManager: ItemController) {
        super(scene)
        this.scene.add.existing(this)

        this.sliceItemManager = itemManager
        this.startingPoint = Phaser.Math.Vector2.ZERO
        this.endingPoint = Phaser.Math.Vector2.ZERO
        this.lastPoints = []

        this.slashFX.push(this.scene.sound.add('swoosh_soundeffect', { volume: 0.5 }))
        this.slashFX.push(this.scene.sound.add('swoosh_soundeffect2', { volume: 0.5 }))



        this.scene.input.on('pointerdown', (pointer: Phaser.Input.Pointer) => {
            this.startingPoint.x = pointer.position.x;
            this.startingPoint.y = pointer.position.y
        })

        this.scene.input.on('pointermove', (pointer: Phaser.Input.Pointer) => {
            if (!this.scene.input.activePointer.leftButtonDown())
                return


            const temp: SwordPoint = {
                x: pointer.x,
                y: pointer.y,
                time: 4
            }
            this.lastPoints.push(temp)
        })

        this.scene.input.on('pointerup', (pointer: Phaser.Input.Pointer) => {
            this.lastPoints = []
            this.clear()
        })

    }

    protected preUpdate(time: number, delta: number): void {
        this.clear()
        if(this.scene.gameover) {
             return
        }
        
        if (!this.scene.input.activePointer.leftButtonDown()) {
            return
        }

        this.endingPoint = this.scene.input.activePointer.position

        // draw line
        if (this.lastPoints.length > 4) {
            this.lineStyle(1, 0xFFFF00, 1.0);
            this.beginPath();
            this.moveTo(this.lastPoints[0].x, this.lastPoints[0].y);
            for (let index = 1; index < this.lastPoints.length - 4; ++index) {
                const point = this.lastPoints[index];
                this.lineStyle(
                    linearInterpolation(index / (this.lastPoints.length - 4), 0, 32),
                    Constants.COLOR_WHITE_INT,
                    0.5
                );
                this.lineTo(point.x, point.y);
            }
            let count = 0;
            for (let index = this.lastPoints.length - 4; index < this.lastPoints.length; ++index) {
                const point = this.lastPoints[index];
                this.lineStyle(
                    linearInterpolation(count++ / 4, 20, 0),
                    Constants.COLOR_WHITE_INT,
                    1.0
                );
                this.lineTo(point.x, point.y);
            }
            this.strokePath();
            this.closePath();
        }
        for (let index = 0; index < this.lastPoints.length; ++index) {
            const point = this.lastPoints[index];

            point.time -= 0.25;
            if (point.time <= 0) {
                this.lastPoints.splice(index, 1);
                index -= 1;
            }
        }
        

        // check if mouse has intersected a SliceItem
        const slashLine = new Phaser.Geom.Line(this.startingPoint.x, this.startingPoint.y, this.endingPoint.x, this.endingPoint.y)
        this.sliceItemManager.getAvailableSliceObjects().forEach( (item: ItemBase)  => {
            this.checkIfSliced(item, slashLine)
        })

        this.startingPoint.x = this.endingPoint.x
        this.startingPoint.y = this.endingPoint.y
    }


    checkIfSliced(object: ItemBase, slashLine: Phaser.Geom.Line) {
        if(this.scene.gameover) {
            return
        }

        if (object.isDone || object.isSliced) {
            return
        }

        // check for intersected
        const point = new Phaser.Geom.Point(object.x, object.y)
        if (Phaser.Geom.Intersects.PointToLine(point, slashLine, 32 * Scaling.DPR)) {
            // receiver: sliceitemBase
            object.slice()
        }


        // soundfx
        if (this.soundReady) {
            this.slashFX[randomIntBetween(0, this.slashFX.length - 1)].play()
            this.soundReady = false
            this.scene.time.addEvent({
                delay: 1500,
                callback: () => {
                    this.soundReady = true
                }
            })
        }
    }
}
